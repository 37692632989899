<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              v-on:menuAction="menuAction"
              v-bind:heading="pageHeading"
            />
            <header-right
              slot="right-toolbar"
              v-on:reloadThis="reloadThis"
              v-bind:singleTerminatedRevoked="singleTerminatedRevoked"
            />
            <v-card-text
              v-if="!singleTerminatedRevoked && !axiosLoading && !networkError"
            >
              <p class="align-center center-align">
                {{ $t("text.draft-detail-empty") }}
              </p>
            </v-card-text>
            <v-card-text
              v-if="networkError || (axiosLoading && !singleTerminatedRevoked)"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-three-line, image, actions"
              ></v-skeleton-loader>
            </v-card-text>

            <v-card-text class="pa-0" v-if="singleTerminatedRevoked">
              <v-col cols="12" class="pa-0">
                <v-tabs color="blue" class="px-4" v-model="myTab">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                    href="#tab-1"
                    v-if="
                      singleTerminatedRevoked &&
                      singleTerminatedRevoked.memo.memo_status_id != 100
                    "
                    ><v-icon class="mr-2">{{ iconEdit }}</v-icon
                    >{{ $t("tab.draft") }}</v-tab
                  >
                  <v-tab
                    href="#tab-1"
                    v-if="
                      singleTerminatedRevoked &&
                      singleTerminatedRevoked.memo.memo_status_id == 100
                    "
                    ><v-icon class="mr-2">{{ iconMailOpen }}</v-icon
                    >{{ $t("tab.message") }}</v-tab
                  >
                  <v-tab href="#tab-2"
                    ><v-icon class="mr-2">{{ iconHistory }}</v-icon
                    >{{ $t("tab.history") }}</v-tab
                  >
                </v-tabs>
                <v-tabs-items v-model="myTab" touchless>
                  <v-tab-item value="tab-1">
                    <v-container fluid>
                      <v-text-field
                        v-bind:data-hint="$t('introjs.this-is-memo-subject')"
                        class="my-4 memo__revision--title"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-model="hal"
                        hide-details="true"
                        maxlength="250"
                        v-bind:hint="
                          $t('tooltip.min-max-char-for-memo-subject')
                        "
                        v-bind:label="$t('label.memo-subject')"
                        v-bind:disabled="true"
                      ></v-text-field>

                      <froala-editor
                        v-bind:data-hint="$t('introjs.this-is-memo-message')"
                        data-hintposition="middle-middle"
                        data-position="bottom-right-aligned"
                        v-bind:oldEditorData="isi"
                        v-on:editorData="changeEditorData"
                        v-on:froalaIsWorking="froalaIsWorking"
                        v-bind:editorDisabled="true"
                      ></froala-editor>
                      <v-col cols="12 table-responsive-container">
                        <div
                          class="attachment-list mt-4 table-responsive"
                          v-if="
                            (attachments && attachments.length > 0) ||
                            (currAttachments && currAttachments.length > 0)
                          "
                        >
                          <p
                            class="mb-1 text--avore-grey"
                            v-html="$t('text.new-attachment-list')"
                          ></p>
                          <ul>
                            <li
                              v-for="(item, index) in currAttachments"
                              v-bind:key="index"
                              v-bind:value="item.id"
                              class="file--list"
                            >
                              <a href="javascript:;" class="server--file">
                                <span>{{ index + 1 }}.</span>
                                {{ item.file_name }} - {{ item.size | formatSize }}
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      class="ml-2"
                                      color="blue"
                                      v-on:click="downloadAttachment(item)"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <v-icon>{{ iconDownload }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t("tooltip.download-this-file")
                                  }}</span>
                                </v-tooltip>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </v-col>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <history-detail
                            v-bind:singleTerminatedRevoked="
                              singleTerminatedRevoked
                            "
                          ></history-detail>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-card-text>
            <hr class="card-footer" />
            <v-card
              elevation="0"
              class="mt-2 d-flex justify-end no-box-shadow no-border v-sheet--outlined"
            >
              <v-tooltip bottom v-if="!userIsPjpDirSekretaris">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ma-2"
                    outlined
                    color="primary"
                    v-clipboard:copy="isi"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    <v-icon>{{ iconCopy }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.copy-message-to-clipboard") }}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="!userIsPjpDirSekretaris">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ma-2"
                    outlined
                    color="warning"
                    @click="copyAsNewDraft"
                  >
                    <v-icon>{{ iconDuplicate }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("tooltip.copy-as-new-draft") }}</span>
              </v-tooltip>
            </v-card>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import HistoryDetail from "./HistoryDetail";
import { isIntroActive } from "@/constants/config";
import dayjs from "dayjs";
import froalaEditor from "@/components/commonComponents/FroalaEditor";
import "intro.js/minified/introjs.min.css";
import { http } from "@/http/http";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiPen,
  mdiHistory,
  mdiContentSaveOutline,
  mdiDotsHorizontalCircleOutline,
  mdiDeleteAlertOutline,
  mdiCloudDownloadOutline,
  mdiEmailOpenOutline,
  mdiContentCopy,
  mdiContentDuplicate,
} from "@mdi/js";

export default {
  name: "terminated-detail",
  components: {
    HeaderLeft,
    HeaderRight,
    HistoryDetail,
    froalaEditor,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconHistory: mdiHistory,
    iconEdit: mdiPen,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSave: mdiContentSaveOutline,
    iconMenu: mdiDotsHorizontalCircleOutline,
    iconDelete: mdiDeleteAlertOutline,
    iconDownload: mdiCloudDownloadOutline,
    iconMailOpen: mdiEmailOpenOutline,
    iconCopy: mdiContentCopy,
    iconDuplicate: mdiContentDuplicate,
    myTab: 0,
    timeout: null,
    comment: null,
    showPeerDialog: false,
    showCommentDialog: false,
    editorDisabled: false,
    attachments: undefined,
    currAttachments: [],
    selectedPeers: undefined,
    hal: null,
    isi: null,
    submitOptions: undefined,
    singleTerminatedRevoked: undefined,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("terminated", ["axiosLoading", "singleTerminated"]),
    ...mapState("revoked", ["axiosLoading", "singleRevoked"]),
    currentUserType() {
      if (!this.currentUser) return undefined;
      return this.currentUser.level_aplikasi_elemen;
    },
    pageHeading() {
      let result = "";
      if (this.$route.name === "revoked.detail") {
        result = this.$t("card.revoked-detail-title");
      } else if (this.$route.name === "terminated.detail") {
        result = this.$t("card.terminated-detail-title");
      }
      return result;
    },
    userIsPjpDirSekretaris() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR","SEKRETARIS"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
  },
  created() {
    this.reloadThis();
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    singleTerminated(val) {
      if (!val) return;
      this.currAttachments = val.memo_attachments;
      this.hal = val.memo.hal;
      this.isi = val.memo.isi;
      let scopeReviser = ['reviser','approver'];

      if (scopeReviser.includes(val.memo.scope)){
        this.getDraftHistory({ rowId: val.memo.id_hash, tableName :'trx_revisions' });
      } else {
        this.getDraftHistory({ rowId: val.memo.id_hash, tableName :'trx_memos' });
      } 
      this.singleTerminatedRevoked = val;
    },
    singleRevoked(val) {
      if (!val) return;
      this.currAttachments = val.memo_attachments;
      this.hal = val.memo.hal;
      this.isi = val.memo.isi;
      let scopeReviser = ['reviser','approver'];
      if (scopeReviser.includes(val.memo.scope)){
        this.getDraftHistory({ rowId: val.memo.id_hash, tableName :'trx_revisions' });
      } else {
        this.getDraftHistory({ rowId: val.memo.id_hash, tableName :'trx_memos' });
      } 
      this.singleTerminatedRevoked = val;
    },
  },
  methods: {
    ...mapActions("terminated", [
      "setAxiosLoading",
      "setSingleTerminated",
      "loadTerminatedDetail",
      "makeCopyAsNewDraft",
    ]),
    ...mapActions("revoked", [
      "setAxiosLoading",
      "setSingleRevoked",
      "loadRevokedDetail",
    ]),
    ...mapActions("draft", ["setDraftHistory", "getDraftHistory"]),
    menuAction(command) {
      switch (command) {
        case "home":
          if (this.$route.name === "terminated.detail") {
            this.$router.push({ name: "terminated" });
          } else {
            this.$router.push({ name: "revoked" });
          }
          break;
        case "reload":
          this.reloadThis();
          break;
        default:
          break;
      }
    },
    reloadThis() {
      this.setDraftHistory(undefined);
      if (this.$route.name === "terminated.detail") {
        this.setSingleTerminated(undefined);
        this.loadTerminatedDetail({ memoId: this.$route.params.id });
      } else {
        this.setSingleRevoked(undefined);
        this.loadRevokedDetail({ memoId: this.$route.params.id });
      }
    },

    changeEditorData(val) {
      this.isi = val;
    },
    froalaIsWorking() {
      return;
    },
    mapId(obj) {
      if (!obj || obj.length === 0) return [];
      return obj.map(({ id }) => id);
    },
    downloadAttachment(file) {
      if (!file) return;
      // api/memo/{id}/attachments/{attachmentId}
      const options = {
        responseType: "blob",
      };

      this.setAxiosLoading(true);

      http
        .get(`/memo/${file.memo_id}/attachments/${file.id_hash}`, options)
        .then((response) => {
          if (response.status === 500) return;
          if (response) {
            let fileURL = window.URL.createObjectURL(new Blob([response]));
            let fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.file_name);
            document.body.appendChild(fileLink);

            this.setAxiosLoading(false);
            fileLink.click();
          }
        });
    },
    copyAsNewDraft() {
      this.makeCopyAsNewDraft({ memoId: this.$route.params.id });
    },
    onCopy: function () {
      // alert("You just copied the following text to the clipboard: " + e.text);
      this.$dialog.notify.info(
        this.$t("msgbox.you-just-copied-the-memo-message-to-clipboard"),
        {
          position: "top-left",
          timeout: 5000,
        }
      );
    },
    onError: function () {
      this.$dialog.notify.error(
        this.$t("error.failed-to-copy-memo-message-to-clipboard"),
        {
          position: "top-left",
          timeout: 5000,
        }
      );
    },
  },
};
</script>